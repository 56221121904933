<template>
  <v-sheet class="transparent page-sheet">
    <v-row>
      <v-col cols="9">
        <v-btn fab color="pink" dark @click="(disabled=false,d_addlic=true)">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-text-field v-model="search" append-icon="mdi-magnify" clearable></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="idx"
      :search="search"
      class="transparent table-custom"
      :footer-props="foot_props"
      :no-data-text="$t('label.nodata')"
      show-expand
    >
      <template #item.price="{item}">{{item.price|currency}}</template>
      <template #item.email="{item}">{{item.orderinfo?item.orderinfo.email:''}}</template>
      <template #item.status="{item}">{{$t("license.licstat."+item.status)}}</template>
      <template #item.phone="{item}">{{item.orderinfo?item.orderinfo.phone:''}}</template>
      <template #item.hash="{item}">
        <span :id="'td-'+item.idx+'-'+item.hash">{{item.hash}}</span>
        <v-btn icon @click="$api.copyText('td-'+item.idx+'-'+item.hash)" small>
          <v-icon small>mdi-content-copy</v-icon>
        </v-btn>
      </template>
      <template #expanded-item="{item}">
        <td :colspan="headers.length">{{item.orderinfo}}</td>
        <td>
          <v-btn
            icon
            color="error"
            :disabled="item.status!='created'"
            @click.prevent="deleteLicense(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </td>
      </template>
    </v-data-table>
    <v-dialog v-model="d_addlic" persistent width="400">
      <v-card>
        <v-card-title>
          <i18n path="license.dlgttl" />
          <v-spacer></v-spacer>
          <v-btn icon color="error" @click="d_addlic=false" dark>
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-row class="dlgtext">
              <v-col cols="6">
                <v-text-field
                  v-model="licdays"
                  append-icon="mdi-calendar-clock-outline"
                  :label="$t('license.indays')"
                  :rules="[$rules.nonzero]"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="licprice"
                  append-icon="mdi-currency-eur"
                  suffix=".00"
                  type="number"
                  :label="$t('license.ineur')"
                  :rules="[$rules.nonzero]"
                ></v-text-field>
              </v-col>
              <v-col align="center" v-if="lichash">
                <div class="mr-4">{{licidx}}</div>
                <div class="text-h5 font-weight-bold d-inline mr-2" id="license-hash">{{lichash}}</div>
                <v-btn icon color="primary" @click="$api.copyText('license-hash')">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="success" @click="addLicence" :disabled="disabled">
            <v-icon>mdi-check</v-icon>
            <i18n path="btn.ok" />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import commonmixin from "@/mixins/commonlist.js";
const apiBase = "v1/cms/licenses";

export default {
  name: "Licenses",
  mixins: [commonmixin],
  computed: {},
  data() {
    return {
      item: {},
      d_addlic: false,
      disabled: false,
      licprice: 0,
      lichash: "",
      licidx: "",
      licdays: 0,
      d_edit: false,
      headers: [
        {
          text: this.$t("fields.invoice"),
          value: "idx",
        },
        {
          text: this.$t("fields.created"),
          value: "created",
        },
        {
          text: this.$t("fields.updated"),
          value: "updated",
        },
        {
          text: this.$t("fields.email"),
          value: "email",
        },
        {
          text: this.$t("fields.phone"),
          value: "phone",
        },
        {
          text: this.$t("fields.license"),
          value: "hash",
        },
        {
          text: this.$t("fields.duration"),
          value: "duration",
        },
        {
          text: this.$t("fields.price"),
          value: "price",
        },
        {
          text: this.$t("fields.status"),
          value: "status",
        },
      ],
    };
  },
  methods: {
    // loadData() {
    //   this.$api
    //     .apiGetRequest(apiBase, {
    //       sort: this.sortByParam("created"),
    //       page: this.pagination.page - 1,
    //       size: this.pagination.itemsPerPage,
    //     })
    //     .then((response) => {
    //       if (response.content) this.items = [...response.content].sort();
    //       this.totalItems = response.totalElements;
    //     });
    // },
    loadData() {
      this.$api.apiGetRequest(apiBase).then((response) => {
        this.items = [...response];
      });
    },
    addLicence() {
      if (!this.$refs.form.validate()) return;
      this.disabled = true;
      this.lichash = "";
      this.$api
        .apiPostRequest(apiBase + "/insert", {
          price: this.licprice * 100,
          days: this.licdays,
        })
        .then((response) => {
          if (response.idx) {
            this.lichash = response.hash;
            this.licidx = response.idx;
            this.items.push(response);
          }
        });
    },
    deleteLicense(i) {
      this.$api.apiDeleteRequest(apiBase + "/" + i.idx).then((response) => {
        if (response) {
          const ind = this.items.findIndex((e) => e.idx == i.idx);
          if (ind != -1) this.items.splice(ind, 1);
        }
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
.dlgtext >>> input {
  text-align: right;
  font-size: 24px;
}
</style>